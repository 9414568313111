import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Button from 'views/Button.js';
import { CollapseArrow } from 'structure/Layer.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

export const Subscriptions = ({ index, options, utils }) => {

    const panelID = 'subscriptions';
    const [activeSchedule, setActiveSchedule] = useState('monthly');
    const [collapsed, setCollapsed] = useState({});
    const [loading, setLoading] = useState(false);
    const [subscriptionOptions, setSubscriptionOptions] = useState([]);

    const onCollapseChange = (category, val, evt) => {

        // stop event from propagating to the parent element
        evt.stopPropagation();

        // set new value for collapse state
        setCollapsed(props => {
            return update(props, {
                [category]: {
                    $set: val
                }
            });
        });
    }

    const onSubscriptionOptionBuyClick = option => {
        utils.alert.show({
            title: option.title,
            message: `Congratulations on your decision to subscribe to the ${option.title}. To complete your purchase, we'll need to direct you to the Applied Fire Technologies web app where you'll login to your dealership and confirm your subscription.`,
            buttons: [{
                key: 'confirm',
                title: 'Okay',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    window.open(`${API.aft.server}/?route=subscription_confirmation&category=${option.category}&schedule=${activeSchedule}`);
                    return;
                }
            }
        });
    }

    const getAppDescription = () => {
        return 'This is a placeholder description for what the Applied Fire Technologies applications offer. This includes a mobile app for iOS and Android as well as a dealer-facing CRM to manage customers, purchases, and comm link network support. The Applied Fire Technologies app is the central hub for all things Global Health and Safety.';
    }

    const getContent = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <img 
                src={window.theme === 'dark' ? 'images/graci-subscription-graphic-dark.png' : 'images/graci-subscription-graphic-light.png'}
                style={{
                    height: 'auto',
                    marginBottom: 12,
                    maxHeight: 500,
                    maxWidth: '100%',
                    objectFit: 'contain',
                    width: 500
                }} />
                <span style={{
                    ...Appearance.textStyles.heavyHeader(),
                    fontSize: 24,
                    marginBottom: 24
                }}>{'GRACI Platforms and Services'}</span>

                <span style={{
                    ...Appearance.textStyles.title(),
                    color: Appearance.colors.subText(),
                    marginBottom: 48,
                    textAlign: 'center',
                    whiteSpace: 'normal'
                }}>{getAppDescription()}</span>
                
                <BoolToggle 
                color={Appearance.colors.darkGrey}
                disabled={'Monthly'}
                enabled={'Yearly'}
                onChange={val => setActiveSchedule(val ? 'yearly' : 'monthly')}
                value={activeSchedule === 'yearly'}
                style={{
                    width: 250
                }}/>
                <div 
                className={'row p-0'}
                style={{
                    marginTop: 24,
                    width: 'auto'
                }}>
                    {getScheduleOptions()}
                </div>
            </div>
        )
    }

    const getScheduleOptions = () => {
        return subscriptionOptions.map((option, index) => {

            // declare selected scehdule type for subscription option
            let schedule = option.schedules[activeSchedule];

            return (
                <div 
                className={'col-12 col-lg-4 mb-3 mx-0 mt-0 m-lg-0 p-2 px-lg-1'}
                key={index}
                style={{
                    minWidth: 225
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        <div style={{
                            maxWidth: '100%',
                            position: 'relative'
                        }}>
                            <div style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                padding: 24,
                                textAlign: 'center'
                            }}>
                                <img
                                src={`images/${option.image}.png`}
                                style={{
                                    height: 100,
                                    marginBottom: 12,
                                    objectFit: 'contain',
                                    width: 100
                                }} />
                                <span style={{
                                    ...Appearance.textStyles.heavyHeader(),
                                }}>{option.title}</span>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    color: option.color
                                }}>{`${Utils.toCurrency(schedule.amount)} ${schedule.descriptor}`}</span>
                                {typeof(schedule.savings) === 'string' && (
                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        color: window.theme === 'dark' ? 'white' : Appearance.colors.secondary(),
                                        marginTop: 8
                                    }}>{`Save ${schedule.savings}`}</span>
                                )}
                            </div>
                            <div style={{
                                minWidth: 0,
                                textAlign: 'left',
                                ...collapsed[option.category] !== false && {
                                    maxHeight: 500,
                                    overflow: 'hidden'
                                }
                            }}>
                                {option.capabilities.map((capability, index) => {
                                    return (
                                        <span 
                                        key={index}
                                        style={{
                                            ...Appearance.textStyles.subTitle(),
                                            borderTop: `1px solid ${Appearance.colors.divider()}`,
                                            color: Appearance.colors.text(),
                                            display: 'block',
                                            overflow: 'hidden',
                                            padding: '8px 12px 8px 12px',
                                            whiteSpace: 'normal'
                                        }}>{capability}</span>
                                    )
                                })}
                            </div>
                            <div style={{
                                borderTop: `${collapsed[option.category] === false ? 1 : 0}px solid ${Appearance.colors.divider()}`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                minWidth: 0,
                                padding: 12,
                                textAlign: 'center',
                                width: '100%',
                                ...collapsed[option.category] !== false && {
                                    background: window.theme === 'dark' ? `linear-gradient(180deg, rgba(65,65,65,0), rgba(65,65,65,0.9), rgba(65,65,65,1))` : `linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0.9), #FFFFFF)`,
                                    bottom: 0,
                                    height: 150,
                                    left: 0,
                                    position: 'absolute',
                                    right: 0
                                }
                            }}>
                                <div 
                                className={'text-button'}
                                onClick={onCollapseChange.bind(this, option.category, collapsed[option.category] === false ? true : false)}
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    minWidth: 0
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.text(),
                                        marginRight: 8
                                    }}>{collapsed[option.category] === false ? 'View Less' : 'View More'}</span>
                                    <CollapseArrow 
                                    collapsed={typeof(collapsed[option.category]) === 'boolean' ? collapsed[option.category] : true}
                                    onClick={onCollapseChange.bind(this, option.category)} />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            borderTop: `1px solid ${Appearance.colors.divider()}`,
                            padding: 12,
                            width: '100%'
                        }}>
                            <Button
                            color={option.color || 'grey'}
                            label={'Buy Now'}
                            onClick={onSubscriptionOptionBuyClick.bind(this, option)}
                            type={'large'} />
                        </div>
                    </div>
                </div>
            )
        });
    }

    const fetchSubscription = async () => {
        try {
            
            setLoading(true);
            let { options } = await Request.get(utils, '/payments/', {
                type: 'subscription_options'
            });
            setLoading(false);
            setSubscriptionOptions(options);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the subscription options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSubscription();
    }, []);

    return (
        <Panel
        index={index}
        panelID={panelID}
        utils={utils}
        options={{
            ...options,
            loading: loading === true,
            shouldStyle: false
        }}>
            {getContent()}
        </Panel>
    )
}