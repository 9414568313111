import React, { useRef, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

const Panel = ({ children, className, column, index, name, options = {}, style }) => {

    const { maxWidth, removeOverflow, removePadding, shouldStyle, subTitle } = options || {};

    const [animations, setAnimations] = useSpring(() => ({
        opacity: 0,
        top: -50,
        config: { mass: 1, tension: 180, friction: 12 }
    }));
    const contentContainer = useRef(null);

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.2 * index);
            setAnimations({
                top: 0,
                opacity: 1
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        runAnimations();
    }, []);

    return (
        <animated.div
        className={`panel window ${className || `${column || 'col-12'} p-2`}`}
        style={{
            marginBottom: 24,
            position: 'relative',
            ...animations
        }}>
            <div style={{
                maxWidth: maxWidth,
                position: 'relative',
                width: '100%',
                ...style
            }}>
                <div style={{
                    ...shouldStyle !== false && Appearance.styles.panel(),
                    height: '100%'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            padding: 8
                        }}>
                            <span style={{
                                color: Appearance.colors.text(),
                                fontSize: 18,
                                fontWeight: 800,
                                lineHeight: 1.5,
                                marginBottom: 2,
                                maxWidth: '100%',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{name}</span>
                            <span style={{
                                color: Appearance.colors.subText(),
                                fontSize: 14,
                                fontWeight: 600,
                                lineHeight: 1.5,
                                maxWidth: '100%',
                                whiteSpace: 'normal'
                            }}>{subTitle}</span>
                        </div>
                    </div>
                    <div
                    className={'card-body p-0'}
                    style={{
                        position: 'relative'
                    }}>
                        <div
                        ref={contentContainer}
                        className={`card-body-content`}
                        style={{
                            padding: removePadding ? 0 : 15,
                            overflowY: removeOverflow === true ? 'visible' : 'scroll'
                        }}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
    )
}
export default Panel;
